import React, { FC } from "react";
import PropTypes from "prop-types";
import { SiteConfig } from "../setup/config/configTypes";
import { useSelector } from "react-redux";
import { RootState } from "../setup";

interface ILogoProps {
  width?: number;
  height?: number;
}
const Logo: FC<ILogoProps> = ({ width, height }) => {
  // Site config
  const values: SiteConfig = useSelector((state) => (state as RootState).site);
  return (
    <img
      src={(values.company.logo as string) ?? "/logo.png"} // Reemplaza con la URL de tu imagen (SVG o PNG)
      alt="Logo" // Agrega un texto alternativo para la imagen
      width={width}
      height={!width && !height ? 120 : height}
      style={{
        shapeRendering: "geometricPrecision",
        textRendering: "geometricPrecision",
        imageRendering: "initial",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
    />
  );
};
Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
