import { InvoiceResponse } from "../resources/remittance/getInvoiceResponse";
import { PaymentMethod } from "../resources/codifier/paymentMethod";

const filterByCountry = (
  country: string,
  invoice: InvoiceResponse,
  paymentMethods: Array<PaymentMethod>
): boolean => {
  if (country && country !== "") {
    if (
      !invoice.paymentMethodInformation.find((value) => {
        const paymentMethod = paymentMethods?.find(
          (pm) => pm.id === value.paymentMethod
        );
        if (!paymentMethod) return false;
        return paymentMethod?.idCountries.includes(country);
      })
    ) {
      return false;
    }
  }
  return true;
};

const filterByCriptoCurrency = (
  idCrypto: string,
  invoice: InvoiceResponse
): boolean => {
  if (idCrypto && idCrypto !== "") {
    if (invoice?.idRemittanceCoin !== idCrypto) {
      return false;
    }
  }
  return true;
};

const filterByFiatCurrency = (
  idFiat: string,
  invoice: InvoiceResponse
): boolean => {
  if (idFiat && idFiat !== "") {
    if (
      !invoice.paymentMethodInformation.find((value) => value.idCoin === idFiat)
    ) {
      return false;
    }
  }
  return true;
};

const filterByPaymentMethod = (
  paymentMethod: string,
  invoice: InvoiceResponse
): boolean => {
  if (paymentMethod && paymentMethod !== "") {
    if (
      !invoice.paymentMethodInformation.find(
        (value) => value.paymentMethod === paymentMethod
      )
    ) {
      return false;
    }
  }
  return true;
};

const filterByAmount = (
  amount: number | undefined,
  invoice: InvoiceResponse
): boolean => {
  if (amount == undefined) return true;
  if (amount && amount > 0) {
    if (!(amount <= +invoice.available)) {
      return false;
    }
  }
  return true;
};

async function wait(seconds: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(undefined);
    }, seconds * 1000);
  });
}

function objToQueryParams(o: any): string {
  var query = new URLSearchParams();
  for (let k in o) {
    if (o[k] != undefined) query.append(k, o[k]);
  }
  return query.toString();
}
function calcPercent(num: number, total: number) {
  if (num <= 0) return 0;
  return num * (100 / total);
}
//**dataURL to blob**
function dataURLtoBlob(dataurl: any) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

//**blob to dataURL**
function blobToDataURL(blob: any, callback: Function) {
  var a = new FileReader();
  a.onload = function (e) {
    callback(e.target?.result);
  };
  a.readAsDataURL(blob);
}

const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

function base64Decode(str: string): string {
  // Use the built-in Node.js Buffer API for modern environments
  if (typeof Buffer !== "undefined") {
    return Buffer.from(str, "base64").toString("binary");
  }
  // Use the browser's atob for compatibility with older environments
  return atob(str);
}

function decodeJWT(jwt: string): any {
  const parts = jwt.split(".");
  if (parts.length !== 3) {
    throw new Error("Invalid JWT format");
  }

  const base64Payload = parts[1];
  const decodedPayload = base64Decode(base64Payload); // Decode base64 payload

  try {
    const payloadObj = JSON.parse(decodedPayload);
    return payloadObj;
  } catch (err) {
    throw new Error("Failed to parse JWT payload");
  }
}

export {
  decodeJWT,
  dataURLtoBlob,
  blobToDataURL,
  calcPercent,
  filterByAmount,
  filterByCountry,
  filterByCriptoCurrency,
  filterByFiatCurrency,
  filterByPaymentMethod,
  wait,
  objToQueryParams,
  toAbsoluteUrl,
};
