import React from 'react'
import Footer from '../../../layout/Footer/Footer'
import Popovers from '../../../components/bootstrap/Popovers'
import {OPTIONAL_SECTION_ENUM, SiteConfig} from '../../../setup/config/configTypes'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import classNames from 'classnames'
import useCustomNavigation from '../../../hooks/useCustomNavigation'
import {useTranslation} from 'react-i18next'
import Button from '../../../components/bootstrap/Button'
import VerticalSocial from '../../../components/socials/VerticalSocials'

const DefaultFooter = () => {
  const config: SiteConfig = useSelector((state) => (state as RootState).site)
  const navigate = useCustomNavigation()
  const {t} = useTranslation('auth')
  const showFooter = !(config.pages.other?.noShow && config.pages.other.noShow.includes(OPTIONAL_SECTION_ENUM.FOOTER))
  return (
    showFooter ?
    <Footer>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <p>© 2023 {config.company.companyName} | All Rights Reserved</p>
          </div>

          <div className='col'>
            <VerticalSocial></VerticalSocial>
          </div>
          <div className='col-auto'>
            {config.pages.other?.privacyPolicy && (
              <Button
                className={classNames('text-decoration-none me-3 link-light')}
                onClick={() => {
                  navigate('/privacy-policy')
                }}
              >
                {t('privacyPolicy')}
              </Button>
            )}
            {config.pages.other?.termsAndCond && (
              <Button
                className={classNames('link-light text-decoration-none link-light')}
                onClick={() => {
                  navigate('/terms-and-cond')
                }}
              >
                {t('termsOfUse')}
              </Button>
            )}
            {config.pages.other?.aboutUs && (
              <Button
                className={classNames('link-light text-decoration-none link-light')}
                onClick={() => {
                  navigate('/about-us')
                }}
              >
                {t('aboutUs')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Footer> : null
  )
}

export default DefaultFooter
