import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import RegisterInput, { VerifyInput } from "../../resources/auth/registerInput";
import LoginInput from "../../resources/auth/loginInput";
import baseUrls from "../../setup/baseUrls";

export const accountService = createApi({
  reducerPath: "account",
  tagTypes: ["account"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.accounts + "/tenant-member";
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },

  endpoints: (builder) => ({
    getUser: builder.query<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetUserQuery } = accountService;
