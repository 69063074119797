import React from 'react'
import {RouteProps} from 'react-router-dom'
import {pagesMenu, demoPagesMenu, pageLayoutTypesPagesMenu, pagesAdmin} from '../menu'
import DashboardHeader from '../pages/_layout/_headers/DashboardHeader'
import DefaultHeader from '../pages/_layout/_headers/DefaultHeader'

const headers: RouteProps[] = [
  {path: demoPagesMenu.login.path, element: null},
  {path: demoPagesMenu.signUp.path, element: null},
  {path: demoPagesMenu.page404.path, element: null},
  {path: pagesMenu.dashboard.path, element: <DefaultHeader />},
  {path: pagesMenu.sendMoney.path, element: <DefaultHeader />},
  {path: pagesAdmin.admin.path, element: <DefaultHeader /> },
  {path: pagesAdmin.liquidity.path, element: <DefaultHeader/>},
  {path: pagesAdmin.deduction.path, element: <DefaultHeader/>},
  {path: pagesAdmin.operations.path, element: <DefaultHeader/>},
  {path: pagesMenu.home.path, element: <DashboardHeader />},
  {
    path: '/about-us',
    element: <DefaultHeader />,
  },
  {
    path: '/terms-and-cond',
    element: <DefaultHeader />,
  },
  {
    path: '/privacy-policy',
    element: <DefaultHeader />,
  },
  {path: '/operation/*', element: <DefaultHeader />},
  {
    path: `/*`,
    element: null,
  },
]

export default headers
