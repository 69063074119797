import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import useDarkMode from "../hooks/useDarkMode";
import { SiteConfig } from "../setup/config/configTypes";
import { useSelector } from "react-redux";
import { RootState } from "../setup";
import chroma from "chroma-js";
import { Image } from "react-bootstrap";


const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { darkModeStatus }  = useDarkMode();
  const {colors}: SiteConfig = useSelector((state) => (state as RootState).site);

  const changeLanguage = (value: string | undefined) => {
    if (value)
        i18n.changeLanguage(value);
  };



  const languageOptions  = [
    { value: 'en', label: (
      <div>
        <Image src='https://app.fusyona.com/media/flags/united-states.svg' height='20px' width='20px' roundedCircle/>
        <span className="ms-3">EN</span>
      </div>
    ),},
  { value: 'es', label: (
    <div>
      <Image src='https://app.fusyona.com/media/flags/spain.svg' height='20px' width='20px' roundedCircle/>
      <span className=" ms-3">ES</span>
    </div>
  ),},
  ]

  const initialOption = languageOptions.find((v)=>v.value == i18n.language );


  return (
    <Select
        className="basic-single form-control p-0"
        classNamePrefix="select"
        onChange={(v)=>changeLanguage(v?.value)}
        defaultValue={initialOption}
        isClearable={false}
        isSearchable={false}
        styles={{
          dropdownIndicator: (base, props) =>( {
            display: 'none'
          }),
          indicatorSeparator: (base, props) =>( {
            display: 'none'
          }),
          control: (baseStyles, state) => {
            const color = chroma(colors.secondary );
            return {
          ...baseStyles,
          minWidth: 50,
          border:'none',
          boxShadow: 'none',
          borderRadius: 10,
         backgroundColor: state.isFocused ? color.alpha(0.1).css() : "transparent"
          }},
          singleValue: (baseStyles, state) => ({
            ...baseStyles,
            
            color: darkModeStatus ? 'white' : "gray",
          }),
          container: (baseStyles, state) => ({
            ...baseStyles,
            display: 'contents',
            height: 50,
                    }),
          menu:  (baseStyles, state) => ({
            ...baseStyles,
            background: undefined,
            right: 20,
            border:'none',
            maxWidth: 75
          }),
          menuList:  (baseStyles, state) => ({
            ...baseStyles,
            border:'none',
            textAlign: 'center',
            borderRadius: 10,
            padding: 0,
          }),
          option: (baseStyles, state) => {
            const color = chroma(colors.secondary )
            const isSelected = state.isSelected;
            const isFocused = state.isFocused;
            return {
            ...baseStyles,
      backgroundColor: isSelected
        ? colors.secondary
        : isFocused
        ? color.alpha(0.1).css()
        : undefined,
      color:  isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : colors.secondary,
      ':active': {
        ...baseStyles[':active'],
        backgroundColor: isSelected
            ? colors.secondary
            : color.alpha(0.3).css(),
      },
          }}
        }

        }
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          spacing: {
            ...theme.spacing,
            menuGutter:0
          },
          colors: {
            ...theme.colors,
            neutral0: 'transparent',
          primary: colors.secondary || theme.colors.primary,
          
          
          },
        })}
        name="language"
        options={languageOptions}
      />
  );
};

export default LanguageSwitcher;
