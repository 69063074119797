import { FetchArgs, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../setup";
import { logout, setCredentials, User } from "../slices/authSlice";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import baseUrls from "../setup/baseUrls";
import { LoginResponse } from "../resources/auth/loginResponse";

const constructBaseQuery = (baseUrl: string) =>
  fetchBaseQuery({
    baseUrl, //'http://localhost:7071/api',// // // //'https://api.fusyona.com/nfts/v1',
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const { token, key } = (getState() as RootState).auth;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      if (key) {
        headers.set("access-token", key);
      }
      //console.log(token, key, baseUrl);
      return headers;
    },
  });

export const baseQueryWithReauthAndRetry = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string,
  retry: number = 10
) => {
  let result: any = {};
  let ind = 0;

  do {
    if (ind >= 2) {
      await sleep(1000 * (ind / 2));
    }
    result = await baseQueryWithReauth(args, api, extraOptions, baseUrl);
    ind = ++ind;
  } while (
    (result?.meta?.response?.status === 500 ||
      result?.meta?.response?.status === 404) &&
    ind < retry
  );
  return result;
};

export const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
  baseUrl: string
) => {
  const baseQuery = constructBaseQuery(baseUrl);
  let result = await baseQuery(args, api, extraOptions);
  if (
    result?.meta?.response?.status === 403 ||
    result?.meta?.response?.status === 401
  ) {
    // TODO: re-auth
    //console.log("try reauth");
    const refreshToken = (api.getState() as RootState).auth.refreshToken;

    // Reauth
    // If has saved token in state try reauth
    if (refreshToken) {
      const baseQuery = constructBaseQuery(baseUrls.tenant);
      const responseAuth = await baseQuery(
        {
          url: "/tenant-member/refresh-token",
          body: { refreshToken: refreshToken },
        },
        api,
        {}
      );
      const data: LoginResponse = responseAuth.data as LoginResponse;

      if (data.userToken) {
        api.dispatch(
          setCredentials({
            user: data.account,
            refreshToken: data.refreshToken,
            token: data.userToken,
          })
        );
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout({}));
      }
    } else {
      api.dispatch(logout({}));
    }
  }

  return result;
};

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
