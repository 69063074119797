import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ThemeProvider } from "react-jss";
import { useFullscreen } from "react-use";
import { Toaster } from "react-hot-toast"; // Import the Toaster from react-hot-toast
import { TourProvider } from "@reactour/tour";
import ThemeContext from "../contexts/themeContext";
import Wrapper from "../layout/Wrapper/Wrapper";
import Portal from "../layout/Portal/Portal";
import useDarkMode from "../hooks/useDarkMode";
import { getOS } from "../helpers/helpers";
import steps, { styles } from "../steps";
import { RootState } from "../setup";
import { useSelector } from "react-redux";
import { SiteConfig, ThemeColors } from "../setup/config/configTypes";
import { setStyleColors, themeColors } from "../setup/config/config";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PageHelmet from "./PageHelmet";

const App = () => {
  getOS();
  // Site config
  const values: SiteConfig = useSelector((state) => (state as RootState).site);
  // Dark Mode
  const { themeStatus, darkModeStatus } = useDarkMode();
  const [theme, setTheme] = useState<ThemeColors>({
    theme: themeStatus,
    ...themeColors(values.colors),
  });

  // Initial hook
  useEffect(() => {
    setStyleColors(theme);
  }, []);

  /*
   * Dark theme hook
   */
  useEffect(() => {
    if (darkModeStatus) {
      document.documentElement.setAttribute("theme", "dark");
    }
    return () => {
      document.documentElement.removeAttribute("theme");
    };
  }, [darkModeStatus]);

  /*
   * Changing settings hook
   */
  useEffect(() => {
    // On config change
    if (values.colors) {
      setTheme({ ...theme, ...themeColors(values.colors) });
    }
  }, [values]);

  // Set colors
  useEffect(() => {
    // on theme colors change
    setStyleColors(theme);
  }, [theme]);

  /**
   * Full Screen
   */
  // @ts-ignore
  const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
  const ref = useRef(null);
  useFullscreen(ref, fullScreenStatus, {
    onClose: () => setFullScreenStatus(false),
  });

  /**
   * Modern Design
   */
  useLayoutEffect(() => {
    if (process.env.REACT_APP_MODERN_DESGIN === "true") {
      document.body.classList.add("modern-design");
    } else {
      document.body.classList.remove("modern-design");
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Toaster /> {/* Include the Toaster component from react-hot-toast */}
      <TourProvider
        steps={steps}
        styles={styles}
        showNavigation={false}
        showBadge={false}
      >
        <div
          ref={ref}
          className="app"
          style={{
            backgroundColor: fullScreenStatus
              ? "var(--bs-body-bg)"
              : undefined,
            zIndex: fullScreenStatus ? 1 : undefined,
            overflow: fullScreenStatus ? "scroll" : undefined,
          }}
        >
{/*
            <AsideRoutes />
             */}

          <Wrapper />
        </div>
      </TourProvider>
    </ThemeProvider>
  );
};

export default App;
