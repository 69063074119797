import React, { lazy } from "react";
import { RouteProps } from "react-router-dom";
import { demoPagesMenu, pagesAdmin, pagesMenu } from "../menu";
import { UserRole } from "../slices/authSlice";
const AUTH = {
  PAGE_404: lazy(() => import("../pages/presentation/auth/Page404")),
  WRAPPER: lazy(() => import("../pages/presentation/auth/AuthWrapper")),
  WRAPPER_REGISTER: lazy(
    () => import("../pages/presentation/auth/AuthWrapperRegister")
  ),
  VERIFY: lazy(() => import("../pages/presentation/auth/Verify")),
  CHANGE_PASSWORD: lazy(
    () => import("../pages/presentation/auth/ChangePassword")
  ),
  CHANGE_PASSWORD_2: lazy(
    () => import("../pages/presentation/auth/ChangePassword2")
  ),
};

const MYCONTENT_PRIVATE = {
  SENDMONEY: lazy(() => import("../pages/send_money/sendMoney")),
  DASHBOARD: lazy(() => import("../pages/dashboard/dashboardPage")),
  OPERATIONS: lazy(() => import("../pages/operation_details/OperationDetails")),
  ADMIN: lazy(() => import("../pages/admin/adminPage")),
  ADMIN_TRANSFERS: lazy(() => import("../pages/admin/transfers/adminTransfers")),
  ADMIN_LIQUIDITY: lazy(()=> import("../pages/admin/liquidity/liquidity")),
  STRIPE_DEDUCTION: lazy(()=> import("../pages/admin/stripeDeduction/stripeDeduction"))
};

const MYCONTENT_PUBLIC = {
  HOME: lazy(() => import("../pages/home/homePage")),
  PRIVACY_POLICY: lazy(() => import("../pages/presentation/statics/PrivacyPolicy")),
  TERMS_COND: lazy(() => import("../pages/presentation/statics/TermsAndConditions")),
  ABOUT_US: lazy(() => import("../pages/presentation/statics/AboutUs")),
};

export interface PropRoutes {
  path: string;
  element: JSX.Element;
  onlyRole?: UserRole[]; // By default ALL
}

export const presentation: PropRoutes[] = [
  /**
   * Landing
   */
  {
    path: pagesMenu.home.path,
    element: <MYCONTENT_PUBLIC.HOME />,
  },

  {
    path: '/terms-and-cond',
    element: <MYCONTENT_PUBLIC.TERMS_COND/>
  },
  {
    path: '/privacy-policy',
    element: <MYCONTENT_PUBLIC.PRIVACY_POLICY/>
  },
  {
    path: '/about-us',
    element: <MYCONTENT_PUBLIC.ABOUT_US/>
  },
  /**
   * Auth
   */
  {
    path: demoPagesMenu.page404.path,
    element: <AUTH.PAGE_404 />,
  },
  {
    path: demoPagesMenu.login.path,
    element: <AUTH.WRAPPER />,
  },
  {
    path: demoPagesMenu.signUp.path,
    element: <AUTH.WRAPPER_REGISTER />,
  },
  {
    path: demoPagesMenu.verify.path,
    element: <AUTH.VERIFY />,
  },
  {
    path: "/auth-pages/forgot-password",
    element: <AUTH.CHANGE_PASSWORD />,
  },
  {
    path: "/auth-pages/change-password",
    element: <AUTH.CHANGE_PASSWORD_2 />,
  },/*
  {
    path: pagesAdmin.admin.path,
    element: <MYCONTENT_PUBLIC.ADMIN />,
  },*/
];
export const auth: PropRoutes[] = [
  /**
   * Dashboard
   */

  {
    path: pagesMenu.dashboard.path,
    element: <MYCONTENT_PRIVATE.DASHBOARD />,
  },

  {
    path: "/operation/:id",
    element: <MYCONTENT_PRIVATE.OPERATIONS />,
  },

  {
    path: pagesMenu.sendMoney.path,
    element: <MYCONTENT_PRIVATE.SENDMONEY />,
  },
  {
    path: pagesAdmin.admin.path,
    element: <MYCONTENT_PRIVATE.ADMIN />,
    onlyRole: [UserRole.OWNER]
  },
  {
    path: pagesAdmin.operations.path,
    element: <MYCONTENT_PRIVATE.ADMIN_TRANSFERS />,
    onlyRole: [UserRole.OWNER]
  },
  {
    path: pagesAdmin.liquidity.path,
    element: <MYCONTENT_PRIVATE.ADMIN_LIQUIDITY />,
    onlyRole: [UserRole.OWNER]
  },
  {
    path: pagesAdmin.deduction.path,
    element: <MYCONTENT_PRIVATE.STRIPE_DEDUCTION />,
    onlyRole: [UserRole.OWNER]
  },
];
