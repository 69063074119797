import { title } from "process";
import COLORS from "../../common/data/enumColors";
import { ColorsConfig, PaymentTypes, SiteConfig, ThemeColors } from "./configTypes";

export const REACT_APP_ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;

const initialConfig: SiteConfig = {
  commission: {
    referralFee: 0,
    shippingFee: 0,
  },
  company: {
    defaultPayment: PaymentTypes.TRANSAK,
    companyName: process.env.REACT_APP_SITE_NAME ?? "Loading...",
    companySlogan: process.env.REACT_APP_COMPANY_SLOGAN ?? "Loading...",
    logo: `${process.env.PUBLIC_URL}${process.env.REACT_APP_LOGO}`,
    logoDark: `${process.env.PUBLIC_URL}${
      process.env.REACT_APP_LOGO_DARK ?? process.env.REACT_APP_LOGO
    }`,
    domain: process.env.REACT_APP_DOMAIN ?? "loading...",
    language: process.env.REACT_APP_LANGUAGE ?? "en",
    email: process.env.REACT_APP_COMPANY_NEWSLETTER ?? "loading...",
  },
  colors: {
    primary: COLORS.PRIMARY.code ?? "#D0651D",
    secondary: COLORS.SECONDARY.code ?? "#A47F5E",
    dark: COLORS.SUCCESS.code ?? "#1B1B37",
    light: COLORS.INFO.code ?? "#EAEAEA",
    success: COLORS.WARNING.code ?? "#5FA728",
    warning: COLORS.DANGER.code ?? "#E5BE01",
    danger: COLORS.DARK.code ?? "#CF0A2C",
    information: COLORS.LIGHT.code ?? "#3F9DD9",
  },
  coinFee: {},
  pages: {
    landing: {},
  },
};

const setFavicon = (faviconUrl: string) => {
  const favicon = document.getElementById("favicon") as HTMLLinkElement; // Accessing favicon element
  if (favicon) favicon.href = faviconUrl; // Update the href property with the new favicon image path
};
const themeColors = (colors: ColorsConfig) => {
  return {
    primary: colors.primary,
    secondary: colors.secondary,
    success: colors.success,
    info: colors.information,
    warning: colors.warning,
    danger: colors.danger,
    dark: colors.dark,
    light: colors.light,
  };
};

const setStyleColors = (theme: ThemeColors) => {
  const root = document.documentElement;
  root.style.setProperty("--my-primary-color", theme.primary);
  root.style.setProperty("--my-secondary-color", theme.secondary);
  root.style.setProperty("--my-success-color", theme.success);
  root.style.setProperty("--my-info-color", theme.info);
  root.style.setProperty("--my-warning-color", theme.warning);
  root.style.setProperty("--my-danger-color", theme.danger);
  root.style.setProperty("--my-light-color", theme.light);
  root.style.setProperty("--my-dark-color", theme.dark);
};

export { initialConfig, setStyleColors, themeColors, setFavicon };
