import { logout, setCredentials } from "../../slices/authSlice";
import baseUrls from "../baseUrls";
import { LoginResponse } from "../../resources/auth/loginResponse";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: any) => {
      const { token, key } = store.getState().auth;
      if (!axios.defaults.headers.common["Authorization"]) {
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
      }
      if (!config.headers["access-token"] && key) {
        config.headers["access-token"] = key;
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    async (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config;
      // If the error status code is 404 or 500, retry up to 5 times
      if (
        error.response &&
        (error.response.status === 404 || error.response.status === 500)
      ) {
        let retries = originalRequest.retries || 0;

        if (retries < 5) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before retrying

          const config = {
            ...originalRequest,
            retries: retries + 1,
          };

          try {
            const response = await axios(config);
            return response;
          } catch (err) {
            return Promise.reject(err);
          }
        }
      }

      // If the error status is 401 and there is no originalRequest
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        const {
          auth: { refreshToken },
        } = store.getState();

        // Call the refresh token API to get a new access token
        return axios
          .post(baseUrls.tenant + "/refresh-token", {
            refreshToken: refreshToken,
          })
          .then((response: any) => {
            const data: LoginResponse = response.data as LoginResponse;
            store.dispatch(
              setCredentials({
                user: data.account,
                refreshToken: data.refreshToken,
                token: data.userToken,
              })
            );
            // Update the Authorization header with the new access token
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.access_token;

            // Resend the original request with the new access token
            return axios(originalRequest);
          })
          .catch((error: any) => {
            // If the refresh token API call fails, redirect the user to the login page
            store.dispatch(logout({}));
          });
      }

      // If the error status is 403, redirect the user to the login page
      if (error.response.status === 403) {
        store.dispatch(logout({}));
      }

      // For all other errors, reject the promise
      return Promise.reject(error);
    }
  );
}
