import { configureStore } from "@reduxjs/toolkit";
import rootSaga from "../../App/sagas/rootSaga";
import authReducer from "../../slices/authSlice";
import siteReducer from "../../slices/siteSlice";
import createSagaMiddleware from "redux-saga";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { remittanceService } from "../../services/remittance/remittanceService";
import { coinService } from "../../services/codifier/coinService";
import { countryService } from "../../services/codifier/countryService";
import { paymentMethodService } from "../../services/codifier/paymentMethodService";
import { accountService } from "../../services/account/accountService";
import { authenticationService } from "../../services/account/authenticationService";
import { memberService } from "../../services/tenant/memberService";
import { applicationService } from "../../services/tenant/applicationService";

const sagaMiddleware = createSagaMiddleware();

export const store: any = configureStore({
  reducer: {
    /** Codifier */
    [coinService.reducerPath]: coinService.reducer,
    [countryService.reducerPath]: countryService.reducer,
    [paymentMethodService.reducerPath]: paymentMethodService.reducer,
    /** P2P */
    [remittanceService.reducerPath]: remittanceService.reducer,
    [accountService.reducerPath]: accountService.reducer,
    [authenticationService.reducerPath]: authenticationService.reducer,
    [memberService.reducerPath]: memberService.reducer,
    [applicationService.reducerPath]: applicationService.reducer,
    site: siteReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(accountService?.middleware)
      .concat(authenticationService?.middleware)
      .concat(memberService?.middleware)
      .concat(applicationService?.middleware)
      /** Codifier */
      .concat(coinService?.middleware)
      .concat(countryService?.middleware)
      .concat(paymentMethodService?.middleware)
      /** P2P */
      .concat(remittanceService?.middleware)
      .concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
