export const dashboardPagesMenu = {
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/",
    icon: "Dashboard",
    subMenu: null,
  },
};

export const demoPagesMenu = {
  auth: {
    id: "auth",
    text: "Auth Pages",
    icon: "Extension",
  },
  login: {
    id: "login",
    text: "Login",
    path: "/auth-pages/login",
    icon: "Login",
  },
  signUp: {
    id: "signUp",
    text: "Sign Up",
    path: "/auth-pages/sign-up",
    icon: "PersonAdd",
  },
  verify: {
    id: "verify",
    text: "Verify Account",
    path: "/auth-pages/verify",
    icon: "Verify",
  },
  page404: {
    id: "Page404",
    text: "404 Page",
    path: "/auth-pages/404",
    icon: "ReportGmailerrorred",
  },
};

export const pageLayoutTypesPagesMenu = {
  layoutTypes: {
    id: "layoutTypes",
    text: "Page Layout Types",
  },
  blank: {
    id: "blank",
    text: "Blank",
    path: "/page-layouts/blank",
    icon: "check_box_outline_blank ",
  },
  pageLayout: {
    id: "pageLayout",
    text: "Page Layout",
    path: "page-layouts",
    icon: "BackupTable",
    subMenu: {
      headerAndSubheader: {
        id: "headerAndSubheader",
        text: "Header & Subheader",
        path: "page-layouts/header-and-subheader",
        icon: "ViewAgenda",
      },
      onlyHeader: {
        id: "onlyHeader",
        text: "Only Header",
        path: "page-layouts/only-header",
        icon: "ViewStream",
      },
      onlySubheader: {
        id: "onlySubheader",
        text: "Only Subheader",
        path: "page-layouts/only-subheader",
        icon: "ViewStream",
      },
      onlyContent: {
        id: "onlyContent",
        text: "Only Content",
        path: "page-layouts/only-content",
        icon: "WebAsset",
      },
    },
  },
  asideTypes: {
    id: "asideTypes",
    text: "Aside Types",
    path: "aside-types",
    icon: "Vertical Split",
    subMenu: {
      defaultAside: {
        id: "defaultAside",
        text: "Default Aside",
        path: "aside-types/default-aside",
        icon: "ViewQuilt",
      },
      minimizeAside: {
        id: "minimizeAside",
        text: "Minimize Aside",
        path: "aside-types/minimize-aside",
        icon: "View Compact",
      },
    },
  },
};

export const pagesMenu = {
  home: {
    id: "home",
    text: "Home",
    path: "/",
    icon: "Home",
    subMenu: null,
    i18n: "menu_home", // Añadido el atributo i18n
  },
  dashboard: {
    id: "dashboard",
    text: "Dashboard",
    path: "/dashboard",
    icon: "Dashboard",
    subMenu: null,
    i18n: "menu_dashboard", // Añadido el atributo i18n
  },
  sendMoney: {
    id: "sendMoney",
    text: "Send Money",
    path: "/dashboard/send_money",
    icon: "Send",
    submenu: null,
    i18n: "menu_sendMoney", // Añadido el atributo i18n
  },
};
export const adminPage =  {
    id: "admin",
    text: "ADMIN",
    path: "/admin",

    i18n: "menu_admin", // Añadido el atributo i18n
  };

export const pagesAdmin = {
  ...pagesMenu,
  admin: adminPage,
  operations: {
    id: "operations",
    text: "Admin Operations",
    path: "/admin/operations",
    i18n: "operations", // Añadido el atributo i18n
  },
  liquidity: {
    id: "liquidity",
    text: "Add Cripto Invoice",
    path: "/add_crypto_invoice/:id",
    i18n: "liquidity", // Añadido el atributo i18n
  },
  deduction: {
    id: "funds",
    text: "Stripe deduction",
    path: "/stripe_deduction",
    i18n: "stripe_deduction", // Añadido el atributo i18n
  },
};

export const homeMenu = {
  home: {
    id: "home",
    text: "Home",
    path: "/",
    icon: "Home",
    subMenu: null,
    i18n: "menu_home", // Añadido el atributo i18n
  },
  login: {
    id: "login",
    text: "Get Started",
    path: "/auth-pages/login",
    icon: "Login",
    submenu: null,
    i18n: "menu_login", // Añadido el atributo i18n
  },
};
