import { forwardRef, ReactElement, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ISubHeaderProps } from "../SubHeader/SubHeader";
import { IPageProps } from "../Page/Page";
import { demoPagesMenu } from "../../menu";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import PageHelmet from "../../pages/PageHelmet";
import useCustomNavigation from "../../hooks/useCustomNavigation";

interface IPageWrapperProps {
  isProtected?: boolean;
  title?: string;
  description?: string;
  children:
    | ReactElement<ISubHeaderProps>[]
    | ReactElement<IPageProps>
    | ReactElement<IPageProps>[];
  className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
  ({ isProtected, title, description, className, children }, ref) => {
    const { user } = useSelector((state) => (state as RootState).auth);

    const navigate = useCustomNavigation();
    useEffect(() => {
      if (isProtected && !user) {
        navigate(`../${demoPagesMenu.login.path}`);
      }
      return () => {};
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        ref={ref}
        className={classNames(
          "page-wrapper",
          "container-fluid",
          "p-0",
          className
        )}
      >
        <PageHelmet
          pageTitle={title}
          pageDescription={description}
        ></PageHelmet>
        {children}
      </div>
    );
  }
);
PageWrapper.displayName = "PageWrapper";
PageWrapper.propTypes = {
  isProtected: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  // @ts-ignore
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
PageWrapper.defaultProps = {
  isProtected: true,
  title: undefined,
  description: undefined,
  className: undefined,
};

export default PageWrapper;
