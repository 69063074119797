import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AuthState {
  user: User
  token?: string
  key?: string
  refreshToken: string
  isBase: boolean
}
export enum UserRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  DEVELOPER = 'developer',
  MEMBER = 'member',
  GUEST = 'guest',
  CUSTOMER = 'customer',
}
export interface User {
  username: string
  email: string
  userUIID: string
  userNickName: string
  status: string
  role: UserRole
}

const ls = localStorage.getItem('adata')

const primaryState: AuthState = {
  user: {
    userUIID: '',
    username: '',
    email: '',
    userNickName: '',
    role: UserRole.CUSTOMER,
  } as User,
  isBase: false,
  refreshToken: '',
}

let initialState: AuthState = {...primaryState}

if (ls) {
  const data = JSON.parse(ls)
  console.log('LS', ls, data)
  initialState = data as AuthState
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setKey: (state, {payload: {key, isBase}}: PayloadAction<{key?: string; isBase: boolean}>) => {
      state.key = key
      state.isBase = isBase
      localStorage.setItem('adata', JSON.stringify(state))
    },
    setCredentials: (
      state,
      {
        payload: {token, user, refreshToken},
      }: PayloadAction<{user?: User; token: string; refreshToken: string}>
    ) => {
      state.user = user ?? state.user
      state.token = token
      state.refreshToken = refreshToken
      localStorage.setItem('adata', JSON.stringify(state))
      console.log('SET', JSON.stringify(state), state)
    },
    logout: (state, {}) => {
      state = {...primaryState, key: state.key, isBase: state.isBase}
      localStorage.setItem('adata', JSON.stringify(state))
      console.log('LOGOUT', JSON.stringify(state), state)
      window.location.href = '/'
    },
  },
})
export const {setCredentials, setKey, logout} = authSlice.actions
export default authSlice.reducer
