import React, {FC} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {SiteConfig} from '../../setup/config/configTypes'
import {RootState} from '../../setup'
import {Nav} from 'react-bootstrap'

interface IVerticalSocialProps {
  width?: number
  height?: number
}
const VerticalSocial: FC<IVerticalSocialProps> = ({width, height}) => {
  // Site config
  const values: SiteConfig = useSelector((state) => (state as RootState).site)
  // Style for the icon
  const iconStyle = {
    width: width ? `${width}px` : '24px', // Default width is 24px
    height: height ? `${height}px` : '24px', // Default height is 24px
  };

  return (
    <Nav defaultActiveKey='/home' className='flex-column'>
      {values.pages.socials?.facebookLink && (
        <Nav.Link href={values.pages.socials?.facebookLink} target='_blank' className='mb-2'>
          <i className='fab fa-facebook' style={iconStyle}></i> Facebook
        </Nav.Link>
      )}
      {values.pages.socials?.instagramLink && (
        <Nav.Link href={values.pages.socials?.instagramLink} target='_blank' className='mb-2'>
          <i className='fab fa-instagram' style={iconStyle}></i> Instagram
        </Nav.Link>
      )}
      {values.pages.socials?.twitterLink && (
        <Nav.Link href={values.pages.socials?.twitterLink} target='_blank' className='mb-2'>
          <i className='fab fa-twitter' style={iconStyle}></i> Twitter
        </Nav.Link>
      )}
      {values.pages.socials?.telegramLink && (
        <Nav.Link href={values.pages.socials?.telegramLink} target='_blank' className='mb-2'>
          <i className='fab fa-telegram' style={iconStyle}></i> Telegram
        </Nav.Link>
      )}
      {values.pages.socials?.discordLink && (
        <Nav.Link href={values.pages.socials?.discordLink} target='_blank' className='mb-2'>
          <i className='fab fa-discord' style={iconStyle}></i> Discord
        </Nav.Link>
      )}
    </Nav>
  )
}
VerticalSocial.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default VerticalSocial
