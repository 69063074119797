const baseUrls = {
  notification: "https://service-notifications.azurewebsites.net/api",
  codifier: "https://service-codifier.azurewebsites.net/api",
  accounts: "https://fusy-account-service.azurewebsites.net/api",
  tenant: "https://fusy-tenant-service.azurewebsites.net/api",
  remittance: "https://remittence-engine.azurewebsites.net/api",
  userprofile: "https://bktuserprofileapi.azurewebsites.net",
  wallet: "https://wallet-api-dev.azurewebsites.net/api",
};
export default baseUrls;
