import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import { PaymentMethod } from "../../resources/codifier/paymentMethod";
import { IPaginatedResponse } from "../../resources/paginationInterfaces";
import baseUrls from "../../setup/baseUrls";

export const paymentMethodService = createApi({
  reducerPath: "payment-method",
  tagTypes: ["payment-method"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.codifier;
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },
  endpoints: (builder) => ({
    getPaymentMethod: builder.query<PaymentMethod, string>({
      query: (id: string) => ({
        url: `payment-method/${id}`,
        method: "GET",
      }),
    }),
    getPaymentMethods: builder.query<IPaginatedResponse<PaymentMethod>, void>({
      query: () => ({
        url: `payment-method`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetPaymentMethodQuery, useGetPaymentMethodsQuery } =
  paymentMethodService;
