import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  baseQueryWithReauth,
  baseQueryWithReauthAndRetry,
} from "../apiService";
import { InvoiceResponse } from "../../resources/remittance/getInvoiceResponse";
import { CreateInvoiceRequest } from "../../resources/remittance/createInvoiceRequest";
import baseUrls from "../../setup/baseUrls";
import {
  IPaginatedResponse,
  IPaginationParams,
} from "../../resources/paginationInterfaces";
import * as utils from "../../setup/utils";
import { AdminStatistic } from "../../resources/remittance/adminStatistic";
import { Invoice } from "../../resources/remittance/invoice";

export const remittanceService = createApi({
  reducerPath: "remittance",
  tagTypes: ["remittance"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.remittance + "/remittance";
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },
  endpoints: (builder) => ({
    createInvoice: builder.mutation<InvoiceResponse, CreateInvoiceRequest>({
      query: (request) => ({
        url: `/`,
        method: "POST",
        body: request,
      }),
    }),
    getStatistic: builder.query<AdminStatistic,void>({
      query: () => ({
        url: '/admin/statistic',
        method: 'GET'
      })
    }),
    getInvoices: builder.query<
      IPaginatedResponse<InvoiceResponse>,
      IPaginationParams | void
    >({
      query: (params?: IPaginationParams) => ({
        url: params ? `?${utils.objToQueryParams(params)}` : "/",
        method: "GET",
      }),
    }),
    getInvoice: builder.query<InvoiceResponse, string>({
      query: (id: string) => ({
        url: `/${id}`,
        method: "GET",
      }),
    }),
    getOwnerInvoice: builder.query<InvoiceResponse, string>({
      query: (id: string) => ({
        url: `/${id}/owner`,
        method: "GET",
      }),
    }),
    getOwnerInvoices: builder.query<
    IPaginatedResponse<InvoiceResponse>,
    IPaginationParams | void>({
      query: (params?: IPaginationParams) => ({
        url: params ? `/owner?${utils.objToQueryParams(params)}` : "/owner",
        method: "GET",
      }),
    }),
    addCryptoInvoice: builder.mutation<InvoiceResponse, string>({
      query: (id: string) => ({
        url: `/${id}/add-crypto-invoice`,
        method: "POST"
      })
    }),
    markAsCompleted: builder.mutation<InvoiceResponse, string>({
      query: (id: string) => ({
        url: `/${id}/mark-as-completed`,
        method: "PATCH"
      })
    }),
    createStripeInvoice: builder.mutation<Invoice, void>({
      query: () => ({
        url: `/admin/stripe/tax-deduction/invoice`,
        method: "POST"
      })
    }),
  }),
});
export const {
  useCreateInvoiceMutation,
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useGetOwnerInvoiceQuery,
  useGetOwnerInvoicesQuery,
  useLazyGetInvoiceQuery,
  useGetStatisticQuery,
  useAddCryptoInvoiceMutation,
useCreateStripeInvoiceMutation,
  useMarkAsCompletedMutation
} = remittanceService;
