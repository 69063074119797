import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import { SiteConfig } from "../setup/config/configTypes";
import { useSelector } from "react-redux";
import { RootState } from "../setup";
import { setFavicon } from "../setup/config/config";
import { Helmet } from "react-helmet-async";

interface PageHelmetProps {
  pageTitle?: string;
  pageDescription?: string;
}
const PageHelmet: React.FC<PageHelmetProps> = ({
  pageTitle,
  pageDescription,
}) => {
  // Site config
  const values: SiteConfig = useSelector((state) => (state as RootState).site);
  const [companyName, setCompanyName] = useState(
    values.company.companyName ?? "Loading"
  );
  useEffect(() => {
    if (values.company.companyName) {
      setCompanyName(values.company.companyName);
    }
    if (typeof values.company.favicon == "string") {
      setFavicon(values.company.favicon);
    } else if (typeof values.company.logo == "string") {
      setFavicon(values.company.logo);
    }
  }, [values]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {companyName} {pageTitle ? `| ${pageTitle}` : ""}
        </title>
        <meta name="robots" content="noindex, follow" />
        <meta
          name="description"
          content={pageDescription ?? values.company.companySlogan}
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {values.company?.favicon && (
          <link
            rel="icon"
            id="favicon"
            href={values.company.favicon as string}
          />
        )}
      </Helmet>
    </React.Fragment>
  );
};

export default PageHelmet;
