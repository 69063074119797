import React, { useContext, useState } from "react";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import Navigation from "../../../layout/Navigation/Navigation";
import { adminPage, pageLayoutTypesPagesMenu, pagesAdmin, pagesMenu } from "../../../menu";
import Popovers from "../../../components/bootstrap/Popovers";
import Button from "../../../components/bootstrap/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../setup";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { UserRole, logout } from "../../../slices/authSlice";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import { OPTIONAL_SECTION_ENUM, SiteConfig } from "../../../setup/config/configTypes";

const DefaultHeader = () => {
  const width = React.useState(window.innerWidth);

  const { user } = useSelector((state) => (state as RootState).auth);
  const config: SiteConfig = useSelector((state) => (state as RootState).site);
  const showLogo = !(config.pages.other?.noShow && config.pages.other.noShow.includes(OPTIONAL_SECTION_ENUM.NAV_LOGO))
  const { t } = useTranslation("translation");
  const dispatch = useAppDispatch();
  const onlogout = () => {
    dispatch(logout({}));
  };
  const getName = () => {
    if (
      !!width[0] &&
      width[0] >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
    ) {
      return user?.username;
    }
    return `${user?.username} (${user?.email})`;
  };

  return (
    <Header showLogo={showLogo}>
      <HeaderLeft className="">
        {showLogo &&
        <h1 className="brand-title align-self-center me-4">
          <Link to="/" aria-label="Logo">
            <Logo height={32} />
          </Link>
        </h1>
        }
      </HeaderLeft>
      <HeaderRight>
        <div className="d-flex">
          <Navigation
            menu={user.role == UserRole.OWNER ? {... pagesMenu, admin: adminPage} : pagesMenu}
            id="header-top-menu"
            horizontal={
              !!width[0] &&
              width[0] >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
            }
          />
          <div className="user-info me-3">
            <div className="user-name">
              <Popovers
                desc={
                  <div>
                    <Button onClick={onlogout}>{t("Logout")}</Button>
                  </div>
                }
              >
                {getName()}
              </Popovers>
            </div>
            <div className="user-sub-title"></div>
          </div>
          <LanguageSwitcher />
        </div>
      </HeaderRight>
    </Header>
  );
};

export default DefaultHeader;
