import { FC, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import { PropRoutes, auth, presentation } from "../../routes/contentRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";
import { User, UserRole } from "../../slices/authSlice";
import useCustomNavigation from "../../hooks/useCustomNavigation";

const ProtectedRoute: FC<{ isAuthenticated: boolean; role: UserRole, page: PropRoutes }> = ({
  isAuthenticated,
  page,
  role,
}) => {
  const navigate = useCustomNavigation();
  
  const checkPermisions = () => {
    // If the property onlyRole is not defined or if defined inlcudes the user role.
    const roleCheck = !page.onlyRole || page.onlyRole.includes(role)
    return isAuthenticated && roleCheck;
  }

  useEffect(() => {
    if (!checkPermisions()) {
      console.error("ERROR 403");
      navigate("/auth-pages/login");
    }
  }, []);

  return checkPermisions() ? page.element : <div>403</div>;
};

const PAGE_404 = lazy(() => import("../../pages/presentation/auth/Page404"));

const ContentRoutes = () => {
  const { token, user, isBase } = useSelector(
    (state) => (state as RootState).auth
  );
  const isAuthenticated = !!token;
  return (
    <Routes>
      {auth.map((page) => (
        <Route
          key={isBase ? page.path : `/:idApp${page.path}`}
          path={isBase ? page.path : `/:idApp${page.path}`}
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated} role={user.role} page={page} />
          }
        />
      ))}
      {presentation.map((page) => (
        <Route
          key={isBase ? page.path : `/:idApp${page.path}`}
          path={isBase ? page.path : `/:idApp${page.path}`}
          element={page.element}
        />
      ))}
      <Route path="/404" element={<PAGE_404 />} />
      <Route path="*" element={<PAGE_404 />} />
    </Routes>
  );
};

export default ContentRoutes;
