export interface SiteConfig {
  company: CompanyConfig;
  colors: ColorsConfig;
  coinFee: { [key: string]: number | string};
  commission?: {
    shippingFee: number;
    referralFee: number;
  };
  pages: PagesConfig;
}

export enum PaymentTypes {
  TRANSAK = 'transak',
  STRIPE = 'stripe'
}

interface CompanyConfig {
  favicon?: string | Blob;
  logo?: string | Blob;
  logoDark?: string | Blob;
  companyName: string;
  companySlogan: string;
  email: string;
  domain: string;
  language: string;
  defaultPayment: string | PaymentTypes // stripe, transak
  thirdPartyApiKey?: {
    stripeKey: string,
    liveChatKey: string,
    simplexKey: string,
    transakKey: string,
  }
}

export interface ColorsConfig {
  primary: string;
  secondary: string;
  information: string;
  warning: string;
  danger: string;
  dark: string;
  light: string;
  success: string;
}

export interface ThemeColors {
  primary: string;
  secondary: string;
  success: string;
  info: string;
  warning: string;
  danger: string;
  dark: string;
  light: string;
  theme: "dark" | "light";
}

export enum OPTIONAL_SECTION_ENUM {
  FOOTER = 'footer',
  NAV_LOGO='navbar logo',
  COMMENTS ='comments'
}

interface PagesConfig {
  landing: {
    imageSection1?: string | Blob;
    services?: {
      title: string;
      icon: string;
      description: string;
    }[];
    comments?: {
      username: string;
      comment: string;
      url?: string;
    }[];
  };
   other?: {
    termsAndCond: string
    privacyPolicy: string
    aboutUs: string
    noShow?: OPTIONAL_SECTION_ENUM[]
  }
  socials?: {
    instagramLink: string,
    facebookLink: string,
    twitterLink: string,
    discordLink: string,
    telegramLink: string,
  }
  send?: {
    sendTitle?: string;
    sendSubtitle?: string;
    image?: string;
    paymentMethodsId?: Array<string>;
  };
  dashboard?: {
    transferTitle?: string;
    transferSubtitle?: string;
    detailsTitle?: string;
  };
}
