import React from "react";
import { Route, Routes } from "react-router-dom";
import headers from "../../routes/headerRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";

const HeaderRoutes = () => {
  const { isBase } = useSelector((state) => (state as RootState).auth);
  return (
    <Routes>
      {headers.map((page) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Route
          key={isBase ? page.path : `/:idApp${page.path}`}
          path={isBase ? page.path : `/:idApp${page.path}`}
          element={page.element}
        />
      ))}
    </Routes>
  );
};

export default HeaderRoutes;
