import { createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import RegisterInput from "../../resources/auth/registerInput";
import LoginInput from "../../resources/auth/loginInput";
import { LoginResponse } from "../../resources/auth/loginResponse";
import baseUrls from "../../setup/baseUrls";

export const memberService = createApi({
  reducerPath: "member",
  tagTypes: ["member"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.tenant + "/tenant-member";

    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },

  endpoints: (builder) => ({
    //TODO: register response
    createUser: builder.mutation<{ activationToken: string }, RegisterInput>({
      query: (request) => ({
        url: ``,
        method: "POST",
        body: {
          service: "Password",
          ...request,
        },
      }),
    }),
    loginUser: builder.mutation<LoginResponse, LoginInput>({
      query: (request) => ({
        url: `authenticate`,
        method: "POST",
        body: {
          service: "Password",
          params: request,
        },
      }),
    }),
  }),
});
export const { useLoginUserMutation, useCreateUserMutation } = memberService;
