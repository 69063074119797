import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SiteConfig } from "../setup/config/configTypes";
import { initialConfig } from "../setup/config/config";
import { now } from "moment";

const siteSlice = createSlice({
  name: "site",
  initialState: { ...initialConfig, time: 0 },
  reducers: {
    setConfig: (
      state,
      { payload: { config } }: PayloadAction<{ config: SiteConfig }>
    ) => {
      const t = now();
      document.title = config.company.companyName;
      return { ...state, ...config, time: t };
    },
  },
});
export const { setConfig } = siteSlice.actions;
export default siteSlice.reducer;
