import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import { Coin } from "../../resources/codifier/coin";
import { IPaginatedResponse } from "../../resources/paginationInterfaces";
import baseUrls from "../../setup/baseUrls";

export const coinService = createApi({
  reducerPath: "coin",
  tagTypes: ["coin"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.codifier;
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },
  endpoints: (builder) => ({
    getCoin: builder.query<Coin, string>({
      query: (id: string) => ({
        url: `coin/${id}`,
        method: "GET",
      }),
    }),
    getCoins: builder.query<IPaginatedResponse<Coin>, void>({
      query: () => ({
        url: `coin`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetCoinQuery, useGetCoinsQuery } = coinService;
