import { FC, useEffect, useState } from "react";
import App from "./app";
import { useDispatch, useSelector } from "react-redux";
import baseUrls from "../setup/baseUrls";
import axios, { AxiosResponse } from "axios";
import {
  ApplicationResponse,
  TenantResponse,
} from "../resources/application/applicationResponse";
import { setConfig } from "../slices/siteSlice";
import { setKey } from "../slices/authSlice";
import Loading from "../components/Loading/Loading";
import useCustomNavigation from "../hooks/useCustomNavigation";
import { Status } from "../resources/remittance/invoice";
import { RootState } from "../setup";
import { decodeJWT, wait } from "../setup/utils";

const PreviewPage: FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useCustomNavigation();
  const { token, key } = useSelector((state) => (state as RootState).auth);
  useEffect(() => {
    console.log("0", key);

    /**
     * Application token
     */
    const baseUrl = baseUrls.tenant + "/tenants";
    const hostname = window.location.hostname;
    const url = window.location.href;
    const myHost = "manage.fusyona.com"; // the host for all the applications
    // if the hostname equals myHost the key will be in the url
    let isBase = hostname !== myHost; // tells if is been used from the company's (T) domain or fusyona's (F)

    let idApp = null;
    let tokenApp = null;
    if (!isBase) {
      if (key) {
        tokenApp = decodeJWT(key).application;
      }
      const parts = url.split("/");
      // If has more than 2 parts the key is in the url
      if (parts.length >= 4) {
        // get the key
        idApp = parts[3];
      } else {
        // check if there was some id saved in the localStorage
        const last_key = localStorage.getItem("last_key");
        if (!last_key) {
          // Go to the remittance handler
          window.open(`https://remittances.fusyona.com`);
        } else {
          // Set the saved key
          idApp = last_key;
          console.log("2", idApp);
          window.open(`https://manage.fusyona.com/${last_key}`);
        }
      }
      // save the key
      if (idApp) localStorage.setItem("last_key", idApp);
    }

    if (!key || (!isBase && idApp != tokenApp)) {
      let new_key = isBase ? hostname : idApp;
      console.log("3", new_key, hostname, idApp);
      // new_key = "64efa03ff2a5ace584affe9a";

      axios.get(baseUrl + "/negotiate-key/" + new_key).then((response: any) => {
        if (response.data.key) {
          // if (response.data.status == Status.ACTIVE) {
          loadConfig(response.data.key, baseUrl, isBase);
          // }
        } else {
          window.open(`https://manage.fusyona.com/`);
        }
      });
    } else {
      loadConfig(key, baseUrl, isBase, false);
    }
  }, [navigate]);

  const loadConfig = (
    key: string,
    baseUrl: string,
    isBase: boolean,
    isFirst: boolean = true
  ) => {
    /**
     * Application config
     */
    axios
      .get(baseUrl + "/application/settings", {
        headers: {
          "access-token": key,
        },
      })
      .then((value: AxiosResponse<ApplicationResponse, any>) => {
        if (value.data.siteSettings) {
          dispatch(setConfig({ config: value.data.siteSettings }));
        }

        dispatch(
          setKey({
            key,
            isBase,
          })
        );
        setLoading(false);
      })
      .catch(async () => {
        console.log("catch", isFirst);
        if (!isFirst) {
          dispatch(
            setKey({
              key: undefined,
              isBase,
            })
          );
          await wait(3);
          window.location.reload();
        }
      });
  };
  return loading ? <Loading></Loading> : <App />;
};

export default PreviewPage;
