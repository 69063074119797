import { all, fork, put, takeLatest } from "redux-saga/effects";
import { setConfig } from "../../../slices/siteSlice";

export function* handleSetConfig(action: any) {
  //yield put<any>(
  //    profileEndpoints.newLogin.initiate()
  //);
}

export function* watchOnSetConfig() {
  yield takeLatest(setConfig.type, handleSetConfig);
}

export default function* siteSaga(): any {
  yield all([fork(watchOnSetConfig)]);
}
