import { NavigateOptions, To, useNavigate, useParams } from "react-router-dom";

const useCustomNavigation = () => {
  const { idApp } = useParams();
  const navigate = useNavigate();

  const customNavigate = (to: To, options?: NavigateOptions) => {
    let path = "";
    if (idApp) {
      path += "/" + idApp;
    }
    path += to;
    navigate(path, options);
  };

  return customNavigate;
};

export default useCustomNavigation;
