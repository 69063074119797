import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import Popovers from "../../../components/bootstrap/Popovers";
import Button, { IButtonProps } from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import LANG, { getLangWithKey, ILang } from "../../../lang";
import Dropdown, {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "../../../components/bootstrap/Dropdown";
import showNotification from "../../../components/extras/showNotification";
import Icon from "../../../components/icon/Icon";
import Spinner from "../../../components/bootstrap/Spinner";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo";
import Navigation from "../../../layout/Navigation/Navigation";
import { homeMenu, pagesMenu } from "../../../menu";
import classNames from "classnames";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import { RootState } from "../../../setup";
import { useSelector } from "react-redux";
import { OPTIONAL_SECTION_ENUM, SiteConfig } from "../../../setup/config/configTypes";

const DashboardHeader = () => {
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const styledBtn: IButtonProps = {
    color: darkModeStatus ? "dark" : "light",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };
  const width = React.useState(window.innerWidth);

  const config: SiteConfig = useSelector((state) => (state as RootState).site);
  const showLogo = !(config.pages.other?.noShow && config.pages.other.noShow.includes(OPTIONAL_SECTION_ENUM.NAV_LOGO))
  const { i18n } = useTranslation();

  const changeLanguage = (lng: ILang["key"]["lng"]) => {
    i18n.changeLanguage(lng).then(() =>
      showNotification(
        <span className="d-flex align-items-center">
          <Icon icon={getLangWithKey(lng)?.icon} size="lg" className="me-1" />
          <span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
        </span>,
        'You updated the language of the site. (Only "Aside" was prepared as an example.)'
      )
    );
  };

  /**
   * Language attribute
   */
  useLayoutEffect(() => {
    document.documentElement.setAttribute("lang", i18n.language);
  });

  return (
    <Header>
      <HeaderLeft>
        <div className="brand">
          <div className="brand-logo">
            {showLogo &&
            <h1
            className={classNames(
              "brand-title",
              darkModeStatus ? "text-light" : "text-dark"
              )}
              >
              <Link to="/" aria-label="Logo">
                <Logo height={32} />
              </Link>
            </h1>
            }
          </div>
        </div>
      </HeaderLeft>

      <HeaderRight>
        <div className="d-flex">
          <Navigation
            menu={{ ...homeMenu }}
            id="header-top-menu"
            horizontal={
              !!width[0] &&
              width[0] >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
            }
          />

          <LanguageSwitcher />
        </div>
      </HeaderRight>
    </Header>
  );
};

export default DashboardHeader;
