import React from "react";
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from "react-dom/client"; // For React 18
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./contexts/themeContext";
import i18n from "./i18n";
// Redux
// https://github.com/rt2zz/redux-persist
import { Provider } from "react-redux";
import * as _redux from "./setup";
import { store } from "./setup";
// Axios
import axios from "axios";
import { I18nextProvider } from "react-i18next";
import PreviewPage from "./pages/previewPage";
import { HelmetProvider } from "react-helmet-async";
import PageHelmet from "./pages/PageHelmet";

_redux.setupAxios(axios, store);
const children = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeContextProvider>
        <HelmetProvider>
          <Router>
            <React.StrictMode>
              <PreviewPage />
            </React.StrictMode>
          </Router>
        </HelmetProvider>
      </ThemeContextProvider>
    </I18nextProvider>
  </Provider>
);

const container = document.getElementById("root");

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { axios };
