import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import RegisterInput, {
  ChangePasswordDto,
  ForgotPasswordDto,
  VerifyInput,
} from "../../resources/auth/registerInput";
import { LoginResponse } from "../../resources/auth/loginResponse";
import baseUrls from "../../setup/baseUrls";

export const authenticationService = createApi({
  reducerPath: "authentication",
  tagTypes: ["authentication"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.accounts + "/authentication";
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },

  endpoints: (builder) => ({
    verifyAccount: builder.mutation<{ success: boolean }, VerifyInput>({
      query: (request) => ({
        url: `verify-account`,
        method: "POST",
        body: {
          ...request,
        },
      }),
    }),
    sendVerificationCode: builder.mutation<
      { activationToken: string },
      { email: string }
    >({
      query: (request) => ({
        url: "send-verification-code",
        method: "POST",
        body: { ...request },
      }),
    }),
    sendForgotPassword: builder.mutation<any, ForgotPasswordDto>({
      query: (request) => ({
        url: "send-forgot-password",
        method: "POST",
        body: request,
      }),
    }),
    changeForgotPassword: builder.mutation<any, ChangePasswordDto>({
      query: (request) => ({
        url: "change-forgot-password",
        method: "POST",
        body: request,
      }),
    }),
  }),
});
export const {
  useSendVerificationCodeMutation,
  useVerifyAccountMutation,
  useChangeForgotPasswordMutation,
  useSendForgotPasswordMutation,
} = authenticationService;
