import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import { Country } from "../../resources/codifier/country";
import { IPaginatedResponse } from "../../resources/paginationInterfaces";
import baseUrls from "../../setup/baseUrls";
import { baseQueryWithReauthAndRetry } from "../apiService";

export const countryService = createApi({
  reducerPath: "country",
  tagTypes: ["country"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.codifier;
    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },
  endpoints: (builder) => ({
    getCountry: builder.query<Country, string>({
      query: (id: string) => ({
        url: `country/${id}`,
        method: "GET",
      }),
    }),
    getCountries: builder.query<IPaginatedResponse<Country>, void>({
      query: () => ({
        url: `country`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetCountryQuery, useGetCountriesQuery } = countryService;
