import { all, fork, put, takeLatest } from "redux-saga/effects";
import { setCredentials } from "../../../slices/authSlice";

export function* handleSetCredentials(action: any) {
  //yield put<any>(
  //    profileEndpoints.newLogin.initiate()
  //);
}

export function* watchOnSetCredentials() {
  yield takeLatest(setCredentials.type, handleSetCredentials);
}

export default function* authSaga(): any {
  yield all([fork(watchOnSetCredentials)]);
}
