import { css } from "@emotion/react";
import React from "react";
import { ClipLoader } from "react-spinners";
import "./Loading.scss";

const Loading: React.FC = () => {
  return (
    <div className={"Loading"} data-testid="Loading">
      <ClipLoader color="gray" loading size={150} />
    </div>
  );
};

const CustomLoading: React.FC<{ size?: number }> = ({ size = 150 }) => {
  return (
    <span className={"Loading"} data-testid="Loading">
      <ClipLoader color="gray" loading size={size} />
    </span>
  );
};
export { CustomLoading };
export default Loading;
