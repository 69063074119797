import { createApi, FetchArgs } from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { baseQueryWithReauthAndRetry } from "../apiService";
import baseUrls from "../../setup/baseUrls";
import { SiteConfig } from "../../setup/config/configTypes";
import { ApplicationResponse } from "../../resources/application/applicationResponse";

export const applicationService = createApi({
  reducerPath: "application",
  tagTypes: ["application"],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.tenant + "/tenants" + "/application";

    return baseQueryWithReauthAndRetry(args, api, extraOptions, baseUrl);
  },

  endpoints: (builder) => ({
    updateSettings: builder.mutation<ApplicationResponse, Partial<SiteConfig>>({
      query: (request) => ({
        url: `/settings`,
        method: "PATCH",
        body: request,
      }),
    }),
    getSettings: builder.query<ApplicationResponse, void>({
      query: () => ({
        url: `/settings`,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetSettingsQuery, useUpdateSettingsMutation } =
  applicationService;
